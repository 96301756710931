<!--
 * @Author: your name
 * @Date: 2022-02-08 12:04:33
 * @LastEditTime: 2022-02-08 12:04:33
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \ZZ-payrolltax\src\views\WalkIntoPayroll\CooperationCase.vue
-->
<template>
  <div class="CooperationCase">
    <div class="swiper">
      <div class="position_box top_blue"></div>
      <div class="position_box bot_red"></div>
      <div class="swiper_list">
        <!-- -->
        <van-swipe
          class="my-swipe"
          indicator-color="#1989fa"
          :autoplay="300000"
        >
          <van-swipe-item v-for="item in caseList" :key="item.name">
            <div>
              <h2 class="swiper_title">{{ item.name }}</h2>
              <div
                class="swiper_content"
                v-for="(jtem, jndex) in item.list"
                :key="jtem.title"
              >
                <div class="swiper_content_title">
                  <van-image fit="contain" :src="jtem.icon" />
                  <h3>{{ jtem.title }}</h3>
                </div>
                <div class="content_top">
                  <div
                    :class="
                      jndex % 2 == 0
                        ? 'title_img img_left'
                        : 'title_img img_right'
                    "
                    align="center"
                  >
                    <van-image fit="cover" :src="jtem.image" />
                  </div>

                  <p>
                    {{ jtem.iconTile }}
                  </p>
                </div>

                <div
                  :class="['text', { content_main: !jtem.unfold }]"
                  ref="textBox"
                >
                  <span ref="spanBox"> {{ jtem.enterprise }}</span>
                </div>
                <div
                  class="btn"
                  v-if="jtem.ifOver"
                  @click="collapseExpand(jtem, item)"
                >
                  {{ jtem.unfold ? "收起" : "展开..." }}
                </div>
              </div>

              <!-- <van-image width="100%" fit="cover" :src="item.image" /> -->
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="customer">
      <!-- <div class="customer_title">
        <div class="customer_title_lf">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h3>合作伙伴</h3>
        <div class="customer_title_rt">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div> -->

      <!-- <div class="logo_list">
        <van-image
          fit="cover"
          :src="item"
          v-for="item in logoList"
          :key="item"
        />
      </div> -->
    </div>
    <copyrightIp />
  </div>
</template>
<script>
import { Image as VanImage, Swipe, SwipeItem } from "vant";

import copyrightIp from "@/components/copyrightIp";

// import wx from "weixin-js-sdk";
import { webWX } from "@/api/index";
import wxShare from "../utils/wxShare";
export default {
  components: {
    [VanImage.name]: VanImage,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    copyrightIp,
  },
  data() {
    return {
      caseList: [
        {
          name: "互联网行业/Internet",
          list: [
            {
              title: "特斯拉",
              icon: require("@/assets/img/CooperationCase_icon_01.png"),
              iconTile:
                "特斯拉是全球市值最高的汽车公司，截至2020年，特斯拉已经在中国20多个城市拥有40余家",
              enterprise:
                "2013年进入中国后，中智薪税为特斯拉提供薪酬个税、股票期权政策培训、员工热线咨询等全方位服务，特斯拉是全球市值最高的汽车公司，截至2020年特斯拉已经在中国20多个城市拥有40余家公司。2013年进入中国后中智薪税为特斯拉提供薪酬个税、股票期权政策培训、员工热线咨询等全方位服务，",
              image: require("@/assets/img/CooperationCase_07.png"),
              ifOver: false, // 文本是否超出三行，默认否
              unfold: false, // 文本是否是展开状态 默认为收起
            },
            {
              title: "沛得莱集团",
              icon: require("@/assets/img/CooperationCase_icon_02.png"),
              iconTile:
                "特斯拉是全球市值最高的汽车公司，截至2020年，特斯拉已经在中国20多个城市拥有40余家",
              enterprise:
                "沛得莱集团是为亚洲和欧洲购物中心和零售商业区提供专业基金和资产管理服务的总部设在上海，下设三处分支机构，集团涉及商业运营管理咨询、商业地产管理、物业管理咨询、市场营销策划咨询、商务信息咨询等业务。针对总部及分支机构各项目组复杂的人员结构，中智薪税为沛得莱定制人事云平台。 ",
              image: require("@/assets/img/CooperationCase_08.png"),
              ifOver: false, // 文本是否超出三行，默认否
              unfold: false, // 文本是否是展开状态 默认为收起
            },
          ],
        },
        {
          name: "金融行业/Financial",
          list: [
            {
              title: "特斯拉",
              icon: require("@/assets/img/CooperationCase_icon_01.png"),
              iconTile:
                "特斯拉是全球市值最高的汽车公司，截至2020年，特斯拉已经在中国20多个城市拥有40余家",
              enterprise:
                "2013年进入中国后，中智薪税为特斯拉提供薪酬个税、股票期权政策培训、员工热线咨询等全方位服务，特斯拉是全球市值最高的汽车公司，截至2020年特斯拉已经在中国20多个城市拥有40余家公司。2013年进入中国后中智薪税为特斯拉提供薪酬个税、股票期权政策培训、员工热线咨询等全方位服务，",
              image: require("@/assets/img/CooperationCase_07.png"),
              ifOver: false, // 文本是否超出三行，默认否
              unfold: false, // 文本是否是展开状态 默认为收起
            },
            {
              title: "沛得莱集团",
              icon: require("@/assets/img/CooperationCase_icon_02.png"),
              iconTile:
                "特斯拉是全球市值最高的汽车公司，截至2020年，特斯拉已经在中国20多个城市拥有40余家",
              enterprise:
                "沛得莱集团是为亚洲和欧洲购物中心和零售商业区提供专业基金和资产管理服务的总部设在上海，下设三处分支机构，集团涉及商业运营管理咨询、商业地产管理、物业管理咨询、市场营销策划咨询、商务信息咨询等业务。针对总部及分支机构各项目组复杂的人员结构，中智薪税为沛得莱定制人事云平台。 ",
              image: require("@/assets/img/CooperationCase_08.png"),
              ifOver: false, // 文本是否超出三行，默认否
              unfold: false, // 文本是否是展开状态 默认为收起
            },
          ],
        },
      ],
      logoList: [
        require("@/assets/img/cooperate_logo_01.jpg"),
        require("@/assets/img/cooperate_logo_02.jpg"),
        require("@/assets/img/cooperate_logo_03.jpg"),
        require("@/assets/img/cooperate_logo_04.jpg"),
        require("@/assets/img/cooperate_logo_05.jpg"),
        require("@/assets/img/cooperate_logo_06.jpg"),
        require("@/assets/img/cooperate_logo_07.jpg"),
        require("@/assets/img/cooperate_logo_08.jpg"),
        require("@/assets/img/cooperate_logo_09.jpg"),
        require("@/assets/img/cooperate_logo_10.jpg"),
        require("@/assets/img/cooperate_logo_11.jpg"),
        require("@/assets/img/cooperate_logo_12.jpg"),
        require("@/assets/img/cooperate_logo_13.jpg"),
        require("@/assets/img/cooperate_logo_14.jpg"),
        require("@/assets/img/cooperate_logo_15.jpg"),
        require("@/assets/img/cooperate_logo_16.jpg"),
        require("@/assets/img/cooperate_logo_17.jpg"),
        require("@/assets/img/cooperate_logo_18.jpg"),
        require("@/assets/img/cooperate_logo_19.jpg"),
        require("@/assets/img/cooperate_logo_20.jpg"),
        require("@/assets/img/cooperate_logo_21.jpg"),
        require("@/assets/img/cooperate_logo_22.jpg"),
        require("@/assets/img/cooperate_logo_23.jpg"),
        require("@/assets/img/cooperate_logo_24.jpg"),
        require("@/assets/img/cooperate_logo_25.jpg"),
        require("@/assets/img/cooperate_logo_26.jpg"),
        require("@/assets/img/cooperate_logo_27.jpg"),
        require("@/assets/img/cooperate_logo_28.jpg"),
        require("@/assets/img/cooperate_logo_29.jpg"),
        require("@/assets/img/cooperate_logo_30.jpg"),
      ],
    };
  },
  mounted() {
    // wx.ready(function () {
    //   //需在用户可能点击分享按钮前就先调用
    //   wx.updateAppMessageShareData({
    //     title: "分享标题", // 分享标题
    //     desc: "分享描述", // 分享描述
    //     link: "", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    //     imgUrl: "分享图标", // 分享图标
    //     success: function () {
    //       // 设置成功
    //     },
    //   });
    // });

    // console.log(this.$refs.textBox, "this.$refs.textBox.offsetHeight");
    // 判断是否显示展开收起按钮
    this.caseList.forEach((element) => {
      element.list.forEach((item, index) => {
        item.ifOver =
          this.$refs.spanBox[index].offsetHeight >
          this.$refs.textBox[index].offsetHeight;
      });
    });
  },
  methods: {
    collapseExpand(data, fatherData) {
      fatherData.list.forEach((item) => {
        if (item.title !== data.title) {
          item.unfold = false;
        } else {
          item.unfold = !item.unfold;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.CooperationCase {
  overflow: auto;
  .swiper {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    position: relative;
    .position_box {
      width: 100px;
      height: 100px;
      position: absolute;
      z-index: -1;
    }
    .top_blue {
      background: #434c6b;
      top: 0;
      left: 0;
    }
    .bot_red {
      background: #f75248;
      bottom: 0;
      right: 0;
    }
    .swiper_list {
      width: 100%;
      padding: 20px 10px 10px;
      border-radius: 10px;
      background: #fff;
      .my-swipe {
        padding-bottom: 10px;
      }
      ::v-deep .van-swipe__indicators {
        bottom: 3px;
        .van-swipe__indicator {
          width: 12px;
          height: 4px;
          border-radius: 2px;
          background: #3d8df7;
          opacity: 0.3;
        }
        .van-swipe__indicator--active {
          opacity: 1;
        }
      }
      .swiper_title {
        font-size: 24px;
        font-weight: bold;
        color: #081222;
      }
      .swiper_content {
        padding: 8px 0;
        position: relative;
        .swiper_content_title {
          width: 100%;
          display: flex;
          padding-bottom: 5px;
          padding-left: 5px;
          .van-image {
            width: 20px;
          }
          h3 {
            font-size: 20px;
            padding-left: 6px;
            color: #081222;
          }
        }
        .content_top {
          width: 100%;
          height: 90px;
          padding-bottom: 5px;

          .title_img {
            width: 129px;
            height: 76px;
            border-radius: 8px;
            margin: 0 8px 3px;
            overflow: hidden;
            clear: both;
            float: left;

            .van-image {
              width: 100%;
              height: 100%;
              float: left;
              clear: both;
            }
          }
          .img_left {
            float: left;
          }
          .img_right {
            float: right;
          }
          p {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
          }
        }
        .text {
        }
        .btn {
          transition: 0.5s;
          color: skyblue;
          text-align: right;
          position: absolute;
          z-index: 10;
          bottom: 8px;
          right: 6px;
          background: #fff;
          padding: 0 5px;
        }
        .content_main {
          color: #4a4a4a;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
.case_list {
  width: 100%;
  height: 135px;
  padding: 13px 20px;
  display: flex;
  justify-content: space-between;
  background: #3366cc;
  font-weight: bold;
  color: #fff;
  align-items: end;
  margin-bottom: 6px;
  .case_list_lf {
    h3 {
      font-weight: bold;
      font-size: 20px;
    }
    p {
      font-size: 17px;
    }
    p:nth-child(2) {
      padding-bottom: 10px;
    }
  }
  .case_list_rt {
    width: 112px;
    height: 110px;
  }
}
.customer {
  width: 100%;
  height: 365px;
  // background-image: url("../assets/img/CooperationCase_05.png");
  background-image: url("../assets/img/CooperationCase_06.jpg");
  background-size: 100%;
  padding: 25px 0px;
  .customer_title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
      color: #fff;
      font-size: 22px;
      padding: 6px;
    }
    .customer_title_lf {
      text-align: right;
      text-align: -webkit-right;
      div:nth-child(1) {
        width: 12px;
        height: 2px;
        background: #dcd6d6;
      }
      div:nth-child(2) {
        width: 18px;
        height: 2px;
        background: #dcd6d6;
        margin: 3px 0;
      }
      div:nth-child(3) {
        width: 24px;
        height: 2px;
        background: #dcd6d6;
      }
    }
    .customer_title_rt {
      div:nth-child(1) {
        width: 12px;
        height: 2px;
        background: #dcd6d6;
      }
      div:nth-child(2) {
        width: 18px;
        height: 2px;
        background: #dcd6d6;
        margin: 3px 0;
      }
      div:nth-child(3) {
        width: 24px;
        height: 2px;
        background: #dcd6d6;
      }
    }
  }
  .logo_list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    // grid-template-rows: repeat(4, 50px);
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    padding: 10px;
    .van-image {
      border-radius: 4px;
      overflow: hidden;
    }
  }
}
</style>
